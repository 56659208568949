<template>
  <diva-card>
    <template v-slot:filters v-if="!this.singlePlan">
      <div class="filters__item">
        <button-radio-group :options="typeOptions"
          button-classes="btn-secondary btn-sm"
          v-model="filter.type" @change="loadPlans()"
        >
        </button-radio-group>
      </div>
      <div class="filters__item">
        <base-input
          :name="'search'"
          :type="'text'"
          v-model="filter.name_search"
          :placeholder="getTrans('messages.search')"
          :formClasses="'filters__input-wrapper'"
          @keypress.enter="loadPlans()"
          :inputClasses="'filters__input'">
        </base-input>
      </div>
      <div class="filters__item">
        <el-select v-model="sorting" :class="'filters__select'" @change="loadPlans()">
          <el-option
            v-for="(option, index) in sortingOptions"
            :class="'filterOptionClass'"
            :value="index"
            :key="index"
            :label="option.label"
          >{{option.label}}</el-option>
        </el-select>
      </div>
      <div class="filters__item">
        <base-button size="sm" type="success" @click="loadPlans()">{{getTrans('messages.search')}}</base-button>
      </div>
      <div class="filters__item">
        <base-button size="sm" type="warning" @click="resetFilters">{{getTrans('messages.reset')}}</base-button>
      </div>
    </template>

    <div @scroll="showPagination ? ()=>{} : handleScroll">
      <store-pagination :store="'Plan'" v-if="!loading && showPagination" @update:modelValue="setPage"></store-pagination>
      <content-loader v-if="loading"></content-loader>
      <template v-else>
        <NothingFound v-if="plans.length === 0"></NothingFound>
        <div class="packages-card-grid" v-else>
          <div v-for="plan in plans" :key="plan.id">
            <plan-overview
              :pack="plan"
              @selected="selectPlan(plan)"
              :selected="plan.id === selectedPlan?.id"
              :show-map="showMap"
              :showbuttons="showPackageButtons"
            ></plan-overview>
          </div>
        </div>
        <div class="text-center" v-if="loading && plans.length !== 0">
          <i class="fas fa-spinner fa-2x fa-spin"></i>
        </div>
        <div class="text-center" v-else-if="!(showPagination || isLastPage())">
          <base-button size="sm" type="secondary" @click="loadMore()">{{getTrans('messages.load_more')}}</base-button>
        </div>
      </template>
      <store-pagination :store="'Plan'" v-if="!loading && showPagination" @update:modelValue="setPage"></store-pagination>
    </div>
  </diva-card>
</template>
<script>

import { mapGetters } from "vuex";
import { ElSelect, ElOption } from "element-plus";
import ButtonRadioGroup from "@/components/ButtonRadioGroup";
import InfiniteScroll from "@/mixins/InfiniteScroll";
import Filters from "@/mixins/Filters";
import PlanOverview from "@/views/Plan/Components/PlanOverview";
import NothingFound from "@/components/Content/NothingFound";
import StorePagination from "../../../components/StorePagination.vue";

export default {
  name: 'package-filter-list',
  mixins: [InfiniteScroll, Filters],
  emits: ['update:selectedPlan'],
  components: {
    ElSelect,
    ElOption,
    ButtonRadioGroup,
    PlanOverview,
    NothingFound,
    StorePagination,
},
  props: {
    selectedPlan: {
      type: Object,
    },
    showMap: {
      type: Boolean,
      default: true
    },
    showPackageButtons: {
      type: Boolean,
      default: ()=>{
        return false;
      }
    },
    showPagination: {
      type: Boolean,
      default: ()=>{
        return false;
      }
    }
  },
  data() {
    return {
      plans: [],
      typeOptions: [
        {value: '', label: this.getTrans('messages.all_packages')},
        {value: 'my', label: this.getTrans('messages.my_packages')}
      ],
      sortingOptions: {
        '': {field: '', label: this.getTrans('messages.sorting')},
        name: {field: 'name', direction: 'asc', label: this.getTrans('messages.name_az')},
        nameDesc: {field: 'name', direction: 'desc', label: this.getTrans('messages.name_za')},
        volume: {field: 'volume', direction: 'desc', label: this.getTrans('messages.daily_volume')},
        traffic: {field: 'avg_bid', direction: 'asc', label: this.getTrans('messages.viewers_per_token_09')},
        trafficDesc: {field: 'avg_bid', direction: 'desc', label: this.getTrans('messages.viewers_per_token_90')},
      },
      filter: {
        type: '',
        name_search: '',
      },
      sorting: '',
      singlePlan: !!this.selectedPlan
    };
  },
  computed: {
    ...mapGetters('Plan', {
      storePlans: 'getList',
      loading: 'listLoading',
      pagination: 'pagination',
    })
  },
  methods: {
    resetFilters() {
      for (let key in this.filter) {
        this.filter[key] = '';
      }
      this.sorting = '';
      this.loadPlans();
    },
    loadPlans(page = 1, apply = false) {
      if (this.singlePlan) {
        this.plans = [this.selectedPlan];
      } else {
        const sorting = {};
        if (!apply) {
          this.plans = [];
        }

        if (this.sorting) {
          sorting[this.sortingOptions[this.sorting]['field']] = this.sortingOptions[this.sorting]['direction'];
        }

        this.$store.dispatch('Plan/getPlans', {
          page: page,
          filter: this.collectFilters(),
          sorting: sorting
        }).then(() => {
          this.plans = this.plans.concat(this.storePlans);
        });
      }
    },
    selectPlan(plan) {
      this.$emit('update:selectedPlan', plan);
    },
    handleScroll(el) {
      if (!this.loading && this.scrollShouldLoadMore(el)) {
        this.loadMore();
      }
    },
    loadMore() {
      this.loadPlans(this.pagination.current_page + 1, true);
    },
    setPage(page) {
      this.loadPlans(page)
    }
  },
  mounted() {
    this.loadPlans();
  }
};
</script>
