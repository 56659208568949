<template>
  <DivaSelectableCard :selected="selected" @click="clicked">
    <div class="package-card">
      <plan-world-map :pack="pack" v-if="showMap"></plan-world-map>
      <el-tooltip
        effect="dark"
        placement="top"
        v-if="pack.description && pack.description != 'null'"
        :popper-options="{'max-width':'200px'}"
        v-model="showDescription"
      >
        <template><div @click="showDescription=!showDescription"><h3 class="package-card__title">{{ pack.name }} <i class="fas fa-info-circle text-primary"></i></h3></div></template>
        <template #content>
          <span class="description-tt">{{pack.description}}</span>
        </template>
      </el-tooltip>
      <h3 class="package-card__title" v-else>{{ pack.name }}</h3>
      <ul class="list-group list-group-flush text-center">
        <li class="list-group-item">
          <i
            class="deviceIcon"
            v-for="device in deviceTypesForPack(pack).slice(0, 3)"
            :class="device.icon"
            :key="device.name"
          ></i>
          <span v-if="deviceTypesForPack(pack).length > 3">{{' ...'}}</span>
          <span v-if="deviceTypesForPack(pack).length == 0">{{getTrans('messages.all_devices')}}</span>
        </li>
        <li class="list-group-item">{{getTrans('messages.estimated_visitors')}}</li>
        <li class="list-group-item">
          {{ pack.avg_bid ? Math.floor(100 / pack.avg_bid) : '-' }}
          {{getTrans('messages.per_token')}}
        </li>
        <li class="list-group-item">{{getTrans('messages.daily_volume')}}</li>
        <li class="list-group-item border-bottom-0">
          {{ pack.daily_volume ? pack.daily_volume : '-' }}
        </li>
        <li class="list-group-item border-0 p-1 px-0">
          <span class="badge badge-danger w-100">
            {{
            adType.find(t => t.type == pack.type).name
            }}
            {{getTrans('messages.traffic')}}
          </span>
        </li>

        <li class="list-group-item border-top-0">
          <div class="text-center">
            <country-flag
              v-for="code in countryCodes(pack).codes.slice(0, 3)"
              :key="code"
              :code="code"
            ></country-flag>
            <span v-if="countryCodes(pack).more"> ...</span>
            <span v-if="countryCodes(pack).codes.length == 0">{{getTrans('messages.all_countries')}}</span>
          </div>
        </li>
        <li class="list-group-item" v-if="showbuttons">
          <div class="row">
            <div class="col-4 text-left" >
              <base-button type="primary" @click="boost(pack)" size="sm">{{getTrans('messages.boost')}}</base-button>
            </div>
            <div class="col-8 text-right">
              <el-dropdown trigger="click">
                <base-button type="info" size="sm">
                  {{getTrans('messages.settings')}}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </base-button>
                <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-if="getUser.permissions['plans-create']"
                    @click="copyPackage(pack)"
                  >{{getTrans('messages.duplicate')}}</el-dropdown-item>
                  <el-dropdown-item @click="showDetailsPage(pack)">{{getTrans('messages.details')}}</el-dropdown-item>
                  <el-dropdown-item
                    v-if="
                                  getUser.permissions['plans-update-all'] ||
                                    (getUser.id == pack.user_id &&
                                      getUser.permissions['plans-update'])
                                "
                    @click="editPackage(pack)"
                  >{{getTrans('messages.edit')}}</el-dropdown-item>
                  <el-dropdown-item v-if="getUser.roles.admin">{{getTrans('messages.stats')}}</el-dropdown-item>
                  <el-dropdown-item
                    v-if="
                                  getUser.permissions['plans-update-all'] ||
                                    (getUser.id == pack.user_id &&
                                      getUser.permissions['plans-update'])
                                "
                    @click="deletePackage(pack.id)"
                  >{{getTrans('messages.delete')}}</el-dropdown-item>
                </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </DivaSelectableCard>
  <plan-modal :pack="pack" v-model:show="showDetails" v-if="showDetails"></plan-modal>
</template>
<script>
import { mapGetters } from "vuex";
import {
  ElButton,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElPagination,
  ElTooltip
} from "element-plus";
import PlanWorldMap from "@/components/Diva/Plan/PlanWorldMap.vue";
import PlanModal from "./PlanModal.vue";
import CountryFlag from "@/components/Diva/Tags/CountryFlag.vue";
import PlanMixin from "@/mixins/Plan/PlanMixin.vue";
import DivaSelectableCard from "@/components/Diva/DivaSelectableCard"
export default {
  name: "plan-overview",
  emits: ['selected'],
  props: {
    pack: Object,
    showbuttons: Boolean,
    selected: Boolean,
    showMap: {
      type: Boolean,
      default: true
    },
    openDetails: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    DivaSelectableCard,
    PlanWorldMap,
    PlanModal,
    CountryFlag,
    [ElButton.name]: ElButton,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    [ElPagination.name]: ElPagination,
    ElTooltip
  },
  mixins: [PlanMixin],
  data() {
    return {
      showDescription: false,
      showDetails: false,
      adType: [
        { type: 1, name: "pop" },
        { type: 2, name: "native" },
        { type: 3, name: "push" }
      ],
      deviceIcons: [
        { name: "TV", icon: "fa fa-tv" },
        { name: "Computer", icon: "fa fa-desktop" },
        { name: "Mobile", icon: "fa fa-mobile-alt" },
        { name: "Tablet", icon: "fa fa-tablet-alt" },
        { name: "Bot", icon: "fa fa-robot" },
        { name: "Camera", icon: "fa fa-camera" },
        { name: "Console", icon: "fa fa-gamepad" },
        { name: "Netbook", icon: "fa fa-laptop" },
        { name: "Glass", icon: "fa fa-glasses" },
        { name: "SetTopBox", icon: "fa fa-square" },
        { name: "Watch", icon: "fa fa-clock" }
      ]
    };
  },
  computed: {
    ...mapGetters("User", ["getUser"]),
    ...mapGetters("DeviceType", ["getDeviceTypes", "getDeviceTypeById"]),
    ...mapGetters("Country", ["getCountries", "getCountryById"])
  },
  methods: {
    clicked() {
        this.$emit("selected");
    },
    deletePackage(id) {
      this.$swal.fire({
        showCancelButton: true,
        title: "Delete?",
        text: "Are you sure you want to delete?",
        type: "warning"
      }).then(() => {
        this.$store.dispatch("Plan/delete", id).then(() => {
          this.$toast.success(this.getTrans("messages.package") + ": " + this.getTrans("messages.delete_succeeded"));
        }).catch(()=>{
          this.$toast.success(this.getTrans("messages.package") + ": " + this.getTrans("messages.delete_failed"));
        });
      });
    },
    editPackage(pack) {
      let packCopy = {};
      Object.assign(packCopy, pack);
      this.$router.push({
        name: "edit_package",
        params: { editPackage: JSON.stringify(packCopy) }
      });
    },
    copyPackage(pack) {
      let packCopy = {};
      Object.assign(packCopy, pack);
      delete packCopy.id;
      this.$router.push({
        name: "create_package",
        params: { copyPackage: JSON.stringify(packCopy) }
      });
    },
    showDetailsPage() {
      this.showDetails = true;
    },
  },
  mounted() {
    if (this.openDetails) {
      this.showDetails = true;
    }
  }
};
</script>
<style scoped>
.description-tt {
  max-width: 200px;
  display: block;
}
</style>
