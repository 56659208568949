<template>
  <modal :show="show" @close="close" :modalClasses="'px-4 w-100'" :size="'lg'">
    <div class="row justify-content-center">
      <h6 class="modal-title">{{pack.name}}</h6>
      <div class="close-icon p-4" @click="close">
        <i class="fas fa-times"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <plan-world-map :pack="pack"></plan-world-map>
      </div>
      <div class="col-12 col-lg-6 pb-4">
        <div class="row">
          <div class="col-12 pt-4">
            <h4>{{getTrans('messages.networks')}}</h4>
          </div>
          <div class="col-12" v-if="pack?.targetingData?.networks?.length">{{Object.values(pack?.targetingData?.networks).join(', ')}}</div>
          <div class="col-12" v-else>{{getTrans('messages.all_networks')}}</div>
        </div>
        <div class="row">
          <div class="col-12 pt-4">
            <h4>{{getTrans('messages.countries')}}</h4>
          </div>
          <div class="col-12" v-if="pack?.targetingData?.countries?.length">{{Object.values(pack?.targetingData?.countries).join(', ')}}</div>
          <div class="col-12" v-else>{{getTrans('messages.all_countries')}}</div>
        </div>
        <div class="row">
          <div class="col-12 pt-4">
            <h4>{{getTrans('messages.browsers')}}</h4>
          </div>
          <div class="col-12" v-if="pack?.targetingData?.browsers?.length">{{Object.values(pack?.targetingData?.browsers).join(', ')}}</div>
          <div class="col-12" v-else>{{getTrans('messages.all_browsers')}}</div>
        </div>
        <div class="row">
          <div class="col-12 pt-4">
            <h4>{{getTrans('messages.sources')}}</h4>
          </div>
          <div class="col-12" v-if="pack?.targetingData?.sources?.length">{{Object.values(pack?.targetingData?.sources).join(', ')}}</div>
          <div class="col-12" v-else>{{getTrans('messages.all_sources')}}</div>
        </div>
        <div class="row">
          <div class="col-12 pt-4">
            <h4>{{getTrans('messages.devices')}}</h4>
          </div>
          <div class="col-12" v-if="pack?.targetingData?.device_types?.length">{{Object.values(pack?.targetingData?.device_types).join(', ')}}</div>
          <div class="col-12" v-else>{{getTrans('messages.all_devices')}}</div>
        </div>
        <div class="row">
          <div class="col-12 pt-4">
            <h4>{{getTrans('messages.device_brands')}}</h4>
          </div>
          <div class="col-12" v-if="pack?.targetingData?.device_brands?.length">{{Object.values(pack?.targetingData?.device_brands).join(', ')}}</div>
          <div class="col-12" v-else>{{getTrans('messages.all_device_brands')}}</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 pb-4">
      <div class="row">
        <div class="col-12 pt-4">
          <h3>{{getTrans('messages.description')}}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12 ">
          <span>{{pack.description}}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 col-lg-6 col-xl-4 text-center"
        v-for="(bid, index) in pack.bids"
        :key="index"
      >
        <bid-card
          :bid="bid"
          :targetingData="pack.targetingData"
        ></bid-card>
      </div>
    </div>
  <template v-slot:footer>
    <base-button @click="close">{{getTrans('messages.close')}}</base-button>
  </template>
    </modal>
</template>
<script>
import Modal from "@/components/Modal.vue"
import PlanWorldMap from "@/components/Diva/Plan/PlanWorldMap.vue";
import BidCard from "@/components/Diva/Plan/BidCard.vue";

export default{
  emits: ['update:show'],
  components: {
      Modal,
      PlanWorldMap,
      BidCard,
  },
  props: {
      show: {
        default: false,
      },
      pack: Object,
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    }
  }
}
</script>
<style scoped>
.close-icon {
  position: absolute;
  right: 0;
  top: 0;
}
</style>